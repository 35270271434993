import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import HeaderWithImage from "../header-with-image"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      feature: featuresJson(tags: { regex: "/header/" }) {
        ...FeatureLargeImageFragment
      }
    }
  `)

  const feature = data.feature
  return (
    <HeaderWithImage
      title={feature.title}
      content={feature.content}
      imgFluid={feature.image.childImageSharp.fluid}
      imageAlt={feature.imageAlt}
    />
  )
}
