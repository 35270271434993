import React from "react"
import Img from "gatsby-image"
import { Container, Grid, Header, Segment } from "semantic-ui-react"

export default ({ title, content, imgFluid, imageAlt }) => (
  <Segment basic>
    <Container>
      <Grid columns={2} verticalAlign="middle" stackable>
        <Grid.Column width={5}>
          <Header as="h1">
            {title}
            {content && <Header.Subheader>{content}</Header.Subheader>}
          </Header>
        </Grid.Column>
        <Grid.Column width={11}>
          <Img fluid={imgFluid} alt={imageAlt} />
        </Grid.Column>
      </Grid>
    </Container>
  </Segment>
)
