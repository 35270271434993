import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Header, Segment } from "semantic-ui-react"

import Layout from "../components/layout-desktop"
import SEO from "../components/seo"
import FeaturesHeader from "../components/features/features-header"
import ProductFeaturesGrid from "../components/features/product-features"
import SecurityFeaturesGrid from "../components/features/security-features"

const featuresPage = () => {
  const data = useStaticQuery(graphql`
    query {
      feature: featuresJson(tags: { regex: "/header/" }) {
        image {
          publicURL
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="Features - SixEye"
        description="Find out about the features of the SixEye platform, providing unified remote management for systems integrators."
        image={data.feature.image.publicURL}
      />

      <FeaturesHeader />

      <Container>
        <Segment basic>
          <ProductFeaturesGrid columns={3} />
        </Segment>
        <Header as="h2">Security &amp; Privacy</Header>
        <Segment basic>
          <SecurityFeaturesGrid columns={3} />
        </Segment>
      </Container>
    </Layout>
  )
}
export default featuresPage;
