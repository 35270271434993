import React from "react"
import { Grid } from "semantic-ui-react"

const withFeatureGrid = FeatureItem => ({ columns, items }) => {
  let alternateColumn = true
  return (
    <Grid columns={columns} centered stackable>
      {items.map(({ node }) => (
        <Grid.Column key={node.id}>
          <FeatureItem
            node={node}
            alternateColumn={(alternateColumn = !alternateColumn)}
          />
        </Grid.Column>
      ))}
    </Grid>
  )
}

export default withFeatureGrid
