import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Grid, Header, Image, Segment } from "semantic-ui-react"
import styled from "styled-components"

const StyledItemTopHeader = styled(Header)`
  font-weight: bold;
  margin-top: 0em;
  margin-bottom: 0em;
`

const StyledItemMiddleHeader = styled(Header)`
  font-weight: bold;
  margin-bottom: 0em;
`

const StyledItemParagraph = styled.p`
  font-size: 1.125rem;
  margin-top: 0.6em;
`

const FeatureItemLeftIcon = ({ node }) => (
  <Grid columns={2} verticalAlign="top">
    <Grid.Column textAlign="center" width={4}>
      <Image centered width={56} src={node.image.publicURL} alt={node.imageAlt} />
    </Grid.Column>
    <Grid.Column width={12}>
      <StyledItemTopHeader as="h4">{node.title}</StyledItemTopHeader>
      <StyledItemParagraph dangerouslySetInnerHTML={{ __html: node.content }} />
      {node.link && node.link.internal && (
        <Link to={node.link.internal}>{node.link.content}</Link>
      )}
    </Grid.Column>
  </Grid>
)

const FeatureItemCenterImage = ({ node }) => (
  <Segment basic textAlign="center">
    <Img style={{ height: `140px` }} fixed={node.image.childImageSharp.fixed} alt={node.imageAlt} />
    <StyledItemMiddleHeader as="h3">{node.title}</StyledItemMiddleHeader>
    <StyledItemParagraph dangerouslySetInnerHTML={{ __html: node.content }} />
  </Segment>
)

const FeatureItemLargeImage = ({ node, alternateColumn }) => {
  const imgColumn = (
    <Grid.Column width={11}>
      <Img fluid={node.image.childImageSharp.fluid} alt={node.imageAlt} />
    </Grid.Column>
  )
  const textColumn = (
    <Grid.Column width={5}>
      <StyledItemTopHeader as="h2">{node.title}</StyledItemTopHeader>
      <StyledItemParagraph dangerouslySetInnerHTML={{ __html: node.content }} />
    </Grid.Column>
  )

  return (
    <Grid columns={2} verticalAlign="top" stackable>
      {alternateColumn ? textColumn : imgColumn}
      {alternateColumn ? imgColumn : textColumn}
    </Grid>
  )
}

export { FeatureItemLeftIcon, FeatureItemCenterImage, FeatureItemLargeImage }

export const FeatureIconFragment = graphql`
  fragment FeatureIconFragment on FeaturesJson {
    id
    title
    content
    image {
      publicURL
    }
    imageAlt
    link {
      content
      internal
    }
  }
`

export const FeatureImageFragment = graphql`
  fragment FeatureImageFragment on FeaturesJson {
    id
    title
    content
    image {
      childImageSharp {
        fixed(height: 140, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    imageAlt
  }
`

export const FeatureLargeImageFragment = graphql`
  fragment FeatureLargeImageFragment on FeaturesJson {
    id
    title
    content
    image {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    imageAlt
  }
`
