import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { FeatureItemLeftIcon } from "./feature-items"
import withFeatureGrid from "./with-feature-grid"

const FeatureItemIconGrid = withFeatureGrid(FeatureItemLeftIcon)

export default props => {
  const data = useStaticQuery(graphql`
    query {
      features: allFeaturesJson(filter: { tags: { in: "product" } }) {
        edges {
          node {
            ...FeatureIconFragment
          }
        }
      }
    }
  `)
  return <FeatureItemIconGrid {...props} items={data.features.edges} />
}
